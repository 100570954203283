import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const Terms = () => {
  return (
    <>
      <Container fluid>
        <Row style={{ marginTop: "2rem" }}>
          <Col className="text-left">
            <span style={{ color: "#E22E2E", fontWeight: "bold", fontSize: 24 }}>Termini e condizioni Taporty</span>
          </Col>
        </Row>
        <Row style={{ marginTop: "1rem" }}>
          <Col className="text-left">
            <span style={{ color: "#E22E2E", fontWeight: "bold", fontSize: 20 }}>INTRODUZIONE</span>
          </Col>
        </Row>
        <Row style={{ marginTop: "1rem" }}>
          <Col className="text-left">
            <span>
              I presenti termini e condizioni devono essere letti con attenzione ed accettati prima di effettuare ordini o, in ogni caso, di fruire
              dei servizi forniti da Delivering S.r.l. tramite il sito web www.taporty.it (di seguito sinteticamente denominato "sito”) o le sue
              declinazioni mobile. La conferma di ogni singolo ordine comporta l'espressa accettazione dei presenti termini e condizioni.{" "}
            </span>
          </Col>
        </Row>
        <Row style={{ marginTop: "1rem" }}>
          <Col className="text-left">
            <span style={{ color: "#E22E2E", fontWeight: "bold", fontSize: 20 }}>1. CONTATTI</span>
          </Col>
        </Row>
        <Row style={{ marginTop: "1rem" }}>
          <Col className="text-left">
            <span>
              1.1 Il prestatore dei servizi oggetto delle Condizioni Generali è Delivering S.r.l. (di seguito denominata "Delivering") una società di
              diritto italiano, con sede legale in Grottaferrata (Rm), via delle sorgenti, 93 - 00046 - P.IVA 17763741000.
            </span>
          </Col>
        </Row>
        <Row style={{ marginTop: "1rem" }}>
          <Col className="text-left">
            <span>
              1.2 Delivering è concessionaria del sito web www.taporty.it e di tutte le applicazioni per dispositivi mobili ad esso riferibili.
            </span>
          </Col>
        </Row>
        <Row style={{ marginTop: "1rem" }}>
          <Col className="text-left">
            <span style={{ color: "#E22E2E", fontWeight: "bold", fontSize: 20 }}>2. FINALITÀ</span>
          </Col>
        </Row>
        <Row style={{ marginTop: "1rem" }}>
          <Col className="text-left">
            <span>
              2.1 I termini e le condizioni generali qui di seguito trascritte (di seguito le "Condizioni Generali") si applicano al solo territorio
              italiano e a tutti gli ordini realizzati dagli utenti della Piattaforma (di seguito gli "Ordini").
            </span>
          </Col>
        </Row>
        <Row style={{ marginTop: "1rem" }}>
          <Col className="text-left">
            <span>
              2.2 La Piattaforma offre la possibilità a operatori selezionati del settore della ristorazione (i "Partner") di descrivere e offrire i
              propri prodotti della ristorazione (i "Prodotti") sulla Piattaforma affinché gli utenti che accedono alla stessa (gli "Utenti") possano
              effettuare un Ordine nei confronti del Partner e ricevere l'Ordine effettuato presso l'indirizzo scelto (tutto insieme, il "Servizio").
            </span>
          </Col>
        </Row>
        <Row style={{ marginTop: "1rem" }}>
          <Col className="text-left">
            <span>
              2.3 Le presenti Condizioni Generali non regolano invece la fornitura di servizi o la vendita di prodotti da parte di soggetti diversi da
              Delivering, a cui si faccia riferimento sulla Piattaforma tramite collegamenti ipertestuali o banner. La Società non è dunque
              responsabile della fornitura di servizi e/o prodotti, nonché della conclusione di qualsivoglia contratto tra l'Utente e soggetti terzi
              diversi da Delivering. Per qualunque dubbio, si invita l'Utente a scrivere una e-mail all'indirizzo partner.taporty@gmail.com
            </span>
          </Col>
        </Row>
        <Row style={{ marginTop: "1rem" }}>
          <Col className="text-left">
            <span style={{ color: "#E22E2E", fontWeight: "bold", fontSize: 20 }}>3. GENERALITÀ E AMBITO DI APPLICAZIONE</span>
          </Col>
        </Row>
        <Row style={{ marginTop: "1rem" }}>
          <Col className="text-left">
            <span>
              3.1 Le presenti Condizioni Generali si applicano a tutti gli Ordini effettuati dagli Utenti al Partner tramite la Piattaforma e
              costituiscono l'intero accordo tra le parti, ossia Delivering e gli Utenti, relativamente al suo oggetto e sostituiscono qualsiasi
              precedente contratto, accordo o intesa raggiunta tra le parti relativamente a tale oggetto.
            </span>
          </Col>
        </Row>
        <Row style={{ marginTop: "1rem" }}>
          <Col className="text-left">
            <span>
              3.2 Le Condizioni Generali possono essere modificate in qualsiasi momento. Eventuali modifiche e\o nuove condizioni saranno in vigore
              dal momento della loro pubblicazione nella sezione "Termini e Condizioni" della Piattaforma, e non sono pertanto retroattive. A tale
              fine invitiamo gli utenti ad accedere con regolarità alla Piattaforma e a verificare la pubblicazione delle Condizioni Generali più
              aggiornate.
            </span>
          </Col>
        </Row>
        <Row style={{ marginTop: "1rem" }}>
          <Col className="text-left">
            <span>3.3 Le Condizioni Generali sono quelle in vigore alla data di invio dell'Ordine da parte dell'Utente.</span>
          </Col>
        </Row>
        <Row style={{ marginTop: "1rem" }}>
          <Col className="text-left">
            <span>
              3.4 L'Utente è l'unico responsabile per l'uso della Piattaforma. Delivering non potrà essere considerata responsabile dell'uso non
              conforme, da parte dell'Utente, della Piattaforma in relazione (i) alle norme di legge vigenti o (ii) alle presenti Condizioni Generali.
              In particolare, l'Utente sarà il solo responsabile per la comunicazione di informazioni e di dati non corretti, falsi o relativi a terzi
              soggetti, senza che questi abbiano espresso il loro consenso, nonché in considerazione di un uso non corretto degli stessi.
            </span>
          </Col>
        </Row>
        <Row style={{ marginTop: "1rem" }}>
          <Col className="text-left">
            <span>
              3.5 Delivering, infine, declina ogni responsabilità per eventuali danni derivanti dall'inaccessibilità al Servizio attraverso il Sito o
              l'Applicazione o da eventuali danni causati da virus, file danneggiati, errori, omissioni, interruzioni del servizio, cancellazioni dei
              contenuti, problemi connessi alla rete, ai provider o a collegamenti telefonici e/o telematici, ad accessi non autorizzati, ad
              alterazioni di dati, al mancato e/o difettoso funzionamento delle apparecchiature elettroniche dell'Utente stesso.
            </span>
          </Col>
        </Row>
        <Row style={{ marginTop: "1rem" }}>
          <Col className="text-left">
            <span style={{ color: "#E22E2E", fontWeight: "bold", fontSize: 20 }}>4. OGGETTO</span>
          </Col>
        </Row>
        <Row style={{ marginTop: "1rem" }}>
          <Col className="text-left">
            <span>
              4.1 La Piattaforma consente all'Utente di prenotare e pagare una gamma di Prodotti (prevalentemente pasti e bevande) offerti dai Partner
              tramite un servizio di prenotazione online. Delivering tramite la Piattaforma invierà l'Ordine dell'Utente al Partner il quale preparerà
              i Prodotti scelti nei termini concordati con l'Utente. Delivering provvederà al ritiro dell'Ordine presso il Partner e alla consegna al
              domicilio indicato dall'Utente.
            </span>
          </Col>
        </Row>
        <Row style={{ marginTop: "1rem" }}>
          <Col className="text-left">
            <span>4.2 In base alla struttura sopra descritta, l'Utente instaura:</span>
          </Col>
        </Row>
        <Row style={{ marginTop: "1rem" }}>
          <Col className="text-left">
            <ul>
              <il>
                <span>
                  i. un rapporto contrattuale con Delivering in relazione alla predisposizione dei suddetti sistemi di prenotazione e pagamento via
                  internet, per i quali Delivering non riceve alcun corrispettivo dall'Utente e che sono regolamentati dalle presenti Condizioni
                  Generali e dall'Informativa sulla privacy e sui cookie;
                </span>
              </il>
              <li>
                <span>
                  ii. un differente rapporto contrattuale, sempre con Delivering, relativo alla consegna dei Prodotti presso l'indirizzo indicato
                  dall'Utente, così come disciplinato dal successivo articolo 7;
                </span>
              </li>
              <li>
                <span>
                  iii. un rapporto contrattuale con il Partner selezionato dall'Utente in relazione alla fornitura dei Prodotti ordinati attraverso la
                  Piattaforma. Delivering non è parte di tale rapporto contrattuale, il cui contenuto specifico può variare da Partner a Partner.
                </span>
              </li>
            </ul>
          </Col>
        </Row>
        <Row style={{ marginTop: "1rem" }}>
          <Col className="text-left">
            <span style={{ color: "#E22E2E", fontWeight: "bold", fontSize: 20 }}>5. ACCESSO ALLA PIATTAFORMA E REGISTRAZIONE</span>
          </Col>
        </Row>
        <Row style={{ marginTop: "1rem" }}>
          <Col className="text-left">
            <span>
              5.1 Al fine di fruire dei servizi offerti da Delivering, è necessario registrarsi gratuitamente sul sito web fornendo a Delivering i
              dati necessari e accettando i presenti termini e condizioni. L'indicazione di dati non veritieri e/o riferiti ad altra persona diversa
              dall'Utente determinerà la mancata esecuzione dell'ordine con ogni conseguenza prevista dalla legge.
            </span>
          </Col>
        </Row>
        <Row style={{ marginTop: "1rem" }}>
          <Col className="text-left">
            <span>
              5.2 Solo gli utenti maggiorenni per la legge dello stato italiano possono utilizzare i servizi offerti da Delivering tramite il sito
              web. Accettando i presenti "Termini e Condizioni" si dichiara di essere un Utente maggiorenne.
            </span>
          </Col>
        </Row>
        <Row style={{ marginTop: "1rem" }}>
          <Col className="text-left">
            <span>
              5.3 Ogni Utente che si registrerà sul sito web riceverà via mail le credenziali d'accesso alla propria area riservata, in cui poter
              consultare e aggiornare i propri dati personali, nonché controllare gli ordini effettuati. L'Utente dovrà conservare con cura le
              credenziali ricevute e non può in alcun modo cederle a terzi. Delivering non si assume alcuna responsabilità per l'utilizzo del sito web
              da parte di soggetti venuti in possesso delle suddette credenziali per negligenza dell'Utente o per ogni altra ragione.
            </span>
          </Col>
        </Row>
        <Row style={{ marginTop: "1rem" }}>
          <Col className="text-left">
            <span>
              5.4 L'Utente accetta di tenere Delivering indenne da qualsiasi obbligo risarcitorio, sanzione derivante e\o in qualsiasi modo collegata
              alla violazione da parte dell'Utente delle regole sulla registrazione alla Piattaforma. L'Utente è esclusivo responsabile dell'accesso
              alla Piattaforma mediante le Credenziali di Registrazione e risponde direttamente di ogni danno o pregiudizio arrecato a Delivering o a
              terze parti da un uso improprio, dalla perdita, dall'appropriazione indebita da parte di altri ovvero dalla mancata tutela di
              un'adeguata segretezza delle proprie Credenziali di Registrazione. Tutte le operazioni effettuate tramite le Credenziali di
              Registrazione sono considerate effettuate dall'Utente a cui le Credenziali di Registrazione si riferiscono.
            </span>
          </Col>
        </Row>
        <Row style={{ marginTop: "1rem" }}>
          <Col className="text-left">
            <span>
              5.5 Delivering si riserva la possibilità di rifiutare, a propria esclusiva discrezione, l'iscrizione degli Utenti. Delivering si riserva
              inoltre il diritto di non accettare Ordini, da chiunque provenienti, che risultino anomali in relazione alla quantità dei Prodotti
              acquistati ovvero alla frequenza degli acquisti effettuati sulla Piattaforma.
            </span>
          </Col>
        </Row>
        <Row style={{ marginTop: "1rem" }}>
          <Col className="text-left">
            <span>
              5.6 L'Utente potrà in qualsiasi momento cancellare la propria iscrizione a Delivering tramite l'invio di una mail all'indirizzo
              partner.taporty@gmail.com
            </span>
          </Col>
        </Row>
        <Row style={{ marginTop: "1rem" }}>
          <Col className="text-left">
            <span>
              5.7 Delivering è autorizzata a mettere la Piattaforma offline per qualunque ragione essa ritenga opportuna e non potrà essere ritenuta
              responsabile di un'eventuale indisponibilità, per qualunque ragione, della Piattaforma o di parti di essa in qualunque momento o per
              qualunque periodo di tempo.
            </span>
          </Col>
        </Row>
        <Row style={{ marginTop: "1rem" }}>
          <Col className="text-left">
            <span style={{ color: "#E22E2E", fontWeight: "bold", fontSize: 20 }}>6. INFORMATIVA PER L'UTENTE</span>
          </Col>
        </Row>
        <Row style={{ marginTop: "1rem" }}>
          <Col className="text-left">
            <span>
              In conformità al Decreto Legislativo 9 aprile 2003, n. 70 recante disposizioni in materia di commercio elettronico, Delivering informa
              l'Utente che:
            </span>
          </Col>
        </Row>
        <Row style={{ marginTop: "1rem" }}>
          <Col className="text-left">
            <span>
              6.1 Per concludere il contratto di acquisto di uno o più Prodotti sulla Piattaforma, l'Utente dovrà selezionare i Prodotti richiesti,
              compilare il modulo d'Ordine in formato elettronico e trasmetterlo a Delivering, in via telematica, seguendo le istruzioni che
              compariranno di volta in volta sulla Piattaforma e che accompagneranno le diverse fasi dell'acquisto.
            </span>
          </Col>
        </Row>
        <Row style={{ marginTop: "1rem" }}>
          <Col className="text-left">
            <span>
              6.2 Il contratto è concluso quando Delivering registra ed accetta il modulo d'Ordine, previa verifica della correttezza dei dati
              relativi all'Ordine e dell'avvenuto pagamento.
            </span>
          </Col>
        </Row>
        <Row style={{ marginTop: "1rem" }}>
          <Col className="text-left">
            <span>
              6.3 Prima di procedere alla trasmissione del modulo d'Ordine l'Utente potrà individuare e correggere eventuali errori di inserimento dei
              dati seguendo le istruzioni di volta in volta indicate nella Piattaforma e che accompagneranno le diverse fasi dell'acquisto dei
              Prodotti; una volta registrato il modulo d'Ordine, Delivering invierà all'Utente, all'indirizzo di posta elettronica dal medesimo
              indicato, una e-mail di conferma contenente (i) le informazioni relative alle caratteristiche del Prodotto acquistato e del Partner,
              (ii) dell'ora prevista per la consegna, (iii) l'indicazione dettagliata del prezzo, (iv) del mezzo di pagamento utilizzato, (v) dei
              costi di spedizione e di eventuali costi aggiuntivi nonché (vi) l'indicazione del servizio di assistenza. Si raccomanda di conservare la
              e-mail ricevuta come prova d'acquisto.
            </span>
          </Col>
        </Row>
        <Row style={{ marginTop: "1rem" }}>
          <Col className="text-left">
            <span>
              6.4 Il modulo d'Ordine sarà archiviato nella banca dati di Delivering per il tempo necessario all'evasione dell'Ordine e, comunque, nei
              termini di legge. Per accedere al proprio modulo d'Ordine l'Utente potrà consultare l’apposita nella sua area riservata della
              Piattaforma dove troverà l'elenco di tutti gli Ordini effettuati.
            </span>
          </Col>
        </Row>
        <Row style={{ marginTop: "1rem" }}>
          <Col className="text-left">
            <span style={{ color: "#E22E2E", fontWeight: "bold", fontSize: 20 }}>7. PRENOTAZIONE, ACQUISTO E SPEDIZIONE.</span>
          </Col>
        </Row>
        <Row style={{ marginTop: "1rem" }}>
          <Col className="text-left">
            <span>
              7.1 La Piattaforma consente all'Utente, anche tramite un sistema di geo-localizzazione (attivato su espressa richiesta dell'Utente), di
              individuare i Partner disponibili per l'Ordine e la consegna di Prodotti nell'area selezionata dall'Utente. La Piattaforma indicherà
              inoltre le condizioni applicabili dal Partner tra cui, a titolo indicativo, i tempi disponibili per la consegna di un Ordine. L'Utente
              procedendo con l'invio dell'Ordine accetta espressamente tali condizioni.
            </span>
          </Col>
        </Row>
        <Row style={{ marginTop: "1rem" }}>
          <Col className="text-left">
            <span>
              7.2 Tutti i prezzi indicati nella Piattaforma sono espressi in Euro (€) e si intendono comprensivi di IVA. I prezzi dei Prodotti sono
              definiti dai Partner e possono essere soggetti a variazioni. In ogni caso, all'Utente verrà addebitato il prezzo del Prodotto indicato
              nella Piattaforma nel momento in cui l'Ordine è stato da lui inviato.
            </span>
          </Col>
        </Row>
        <Row style={{ marginTop: "1rem" }}>
          <Col className="text-left">
            <span>
              7.3 Al prezzo del Prodotto è da sommarsi anche il contributo per la consegna del Prodotto. Il costo delle spese di consegna può variare
              sulla base di molteplici fattori: in ogni caso, l'Utente conoscerà l'importo preciso del contributo per la consegna del Prodotto prima
              di procedere al pagamento del medesimo, così come esplicitamente indicato nella Piattaforma al momento dell'Ordine. Tale importo sarà
              infatti evidenziato separatamente sul modulo d'Ordine, precedentemente all'invio del medesimo, e sulla e-mail di conferma dell'Ordine.
            </span>
          </Col>
        </Row>
        <Row style={{ marginTop: "1rem" }}>
          <Col className="text-left">
            <span>
              7.4 La spedizione del Prodotto avverrà a cura dei "riders" di Delivering, al costo sopra indicato. I tempi di consegna del Prodotto
              variano per ogni spedizione e sono legati al Prodotto selezionato e al Partner (per cui, a mero titolo esemplificativo, i tempi di
              consegna varieranno in base ai tempi di preparazione e cottura del Prodotto, alla distanza tra Partner e Utente, etc.). Pertanto
              Delivering non si assume alcuna responsabilità in relazione ai tempi di consegna, non essendo gli stessi legati unicamente all'attività
              di Delivering, ma ai molteplici fattori sopra riportati. l'Utente potrà comunque contattare il servizio clienti di Delivering, anche in
              relazione a ogni problema relativo all'Ordine. Tutte le richieste di informazioni e/o segnalazioni effettuate dagli Utenti del sito
              saranno gestite con la massima tempestività ed accuratezza, al fine di garantire la loro piena soddisfazione.
            </span>
          </Col>
        </Row>
        <Row style={{ marginTop: "1rem" }}>
          <Col className="text-left">
            <span>
              7.5 Delivering darà corso all'Ordine di acquisto solo dopo aver ricevuto conferma dell'autorizzazione al pagamento dell'importo totale
              dovuto, costituito dal prezzo di acquisto, spese di spedizione e da ogni eventuale costo aggiuntivo, come indicato nel modulo d'Ordine,
              ovvero se la modalità di pagamento selezionata è quella del contrassegno.
            </span>
          </Col>
        </Row>
        <Row style={{ marginTop: "1rem" }}>
          <Col className="text-left">
            <span>
              7.6 In nessun caso, Delivering potrà visualizzare e/o acquisire le informazioni relative alla carta di pagamento immesse dall'Utente in
              fase di conclusione dell'Ordine.
            </span>
          </Col>
        </Row>
        <Row style={{ marginTop: "1rem" }}>
          <Col className="text-left">
            <span>
              7.7 Delivering si riserva il diritto di non confermare gli Ordini provenienti da Utenti con i quali siano pendenti controversie o nel
              caso previsto dall'art. 5.5.
            </span>
          </Col>
        </Row>
        <Row style={{ marginTop: "1rem" }}>
          <Col className="text-left">
            <span>7.8 Tutti i Prodotti reperibili sulla Piattaforma sono soggetti alla disponibilità effettiva dei Partner.</span>
          </Col>
        </Row>
        <Row style={{ marginTop: "1rem" }}>
          <Col className="text-left">
            <span>
              7.9 Le immagini ed i colori dei Prodotti offerti in vendita dai Partner potrebbero non essere corrispondenti a quelli reali a causa del
              browser Internet, del monitor utilizzato e, soprattutto, considerata la particolarità dei Prodotti offerti nella Piattaforma. Per tali
              ragioni, Delivering consiglia all'Utente di verificare sempre le caratteristiche riportate nelle schede prodotto legate a ciascun
              Prodotto presente nella Piattaforma.
            </span>
          </Col>
        </Row>
        <Row style={{ marginTop: "1rem" }}>
          <Col className="text-left">
            <span>
              7.10 Fatto salvo quanto sopra, Delivering richiede ai Partner di garantire che tutte le informazioni da loro fornite per la
              visualizzazione del menù sulla Piattaforma siano accurate, complete e non siano in alcun modo fuorvianti.
            </span>
          </Col>
        </Row>
        <Row style={{ marginTop: "1rem" }}>
          <Col className="text-left">
            <span>
              7.11 Resta inteso che, ai sensi della normativa vigente, le bevande alcoliche possono essere ordinate solo da Utenti maggiorenni.
              Pertanto, l'Utente che ordina delle bevande alcoliche dichiara di essere maggiorenne.
            </span>
          </Col>
        </Row>
        <Row style={{ marginTop: "1rem" }}>
          <Col className="text-left">
            <span style={{ color: "#E22E2E", fontWeight: "bold", fontSize: 20 }}>8. VARIAZIONI DI PRENOTAZIONE O ANNULLAMENTI</span>
          </Col>
        </Row>
        <Row style={{ marginTop: "1rem" }}>
          <Col className="text-left">
            <span>
              8.1 Una volta confermato l'invio dell'ordine, questo non potrà più essere modificato, ad eccezione dei casi in cui il prodotto o i
              prodotti ordinati non fossero disponibili per la vendita e/o per la spedizione. In tali ipotesi sarà facoltà dell'Utente modificare o
              annullare l'Ordine e/o la richiesta di spedizione. Al momento della conferma dell'ordine, Delivering lo elaborerà, comunicandolo
              all'Utente tramite e-mail all'indirizzo fornito al momento della registrazione.
            </span>
          </Col>
        </Row>
        <Row style={{ marginTop: "1rem" }}>
          <Col className="text-left">
            <span>
              8.2 Nei casi previsti al precedente art. 8.1 se il pagamento dell'Ordine è già stato eseguito, Delivering effettuerà il rimborso della
              somma pagata dall'Utente (anche parzialmente, in caso di annullamento parziale dell'Ordine), comprensiva delle spese di spedizione,
              immediatamente, e, comunque entro il termine di 7 (sette) giorni dalla comunicazione dell'Utente. L'importo del rimborso sarà comunicato
              per mezzo di e-mail, e accreditato sullo stesso mezzo di pagamento utilizzato per l'acquisto.
            </span>
          </Col>
        </Row>
        <Row style={{ marginTop: "1rem" }}>
          <Col className="text-left">
            <span>
              8.3 Delivering declina ogni responsabilità per eventuali ritardi nell'accredito, che dipendano dall'istituto bancario o dal tipo di
              carta di credito utilizzata per il pagamento. In ogni caso, la data di valuta dell'importo riaccreditato sarà la stessa dell'addebito.
            </span>
          </Col>
        </Row>
        <Row style={{ marginTop: "1rem" }}>
          <Col className="text-left">
            <span>8.4 Resta inteso che Delivering non accetta cancellazioni e modifiche tramite contatto diretto dell'Utente con il Partner.</span>
          </Col>
        </Row>
        <Row style={{ marginTop: "1rem" }}>
          <Col className="text-left">
            <span>
              8.5 Fatte salve le ipotesi di cui all'art. 8.1 che precede, in conformità con quanto previsto dall'art. 59, primo comma, lett. d), del
              Codice del Consumo, l'Utente non può esercitare il diritto di recesso per i Prodotti venduti sulla Piattaforma, nell'ipotesi in cui si
              tratti di beni (per lo più pietanze) che "rischiano di deteriorarsi o scadere rapidamente".
            </span>
          </Col>
        </Row>
        <Row style={{ marginTop: "1rem" }}>
          <Col className="text-left">
            <span style={{ color: "#E22E2E", fontWeight: "bold", fontSize: 20 }}>9. SPEDIZIONE DEI PRODOTTI</span>
          </Col>
        </Row>
        <Row style={{ marginTop: "1rem" }}>
          <Col className="text-left">
            <span>
              9.1 Al momento della conclusione dell'Ordine, l'Utente richiederà la consegna del Prodotto a Delivering, concordando con la stessa luogo
              e ora indicativa di consegna.
            </span>
          </Col>
        </Row>
        <Row style={{ marginTop: "1rem" }}>
          <Col className="text-left">
            <span>
              9.2 L'Utente prende atto e riconosce di essere obbligato a collaborare con Delivering per la consegna dei Prodotti ordinati nei luoghi e
              nei tempi concordati a causa della loro deperibilità, trattandosi di alimenti destinati al consumo immediato.
            </span>
          </Col>
        </Row>
        <Row style={{ marginTop: "1rem" }}>
          <Col className="text-left">
            <span>
              9.3 I tempi di consegna sono quelli indicati nell'Ordine di acquisto registrato nella Piattaforma e specificati nella mail di conferma
              dell'Ordine di acquisto.
            </span>
          </Col>
        </Row>
        <Row style={{ marginTop: "1rem" }}>
          <Col className="text-left">
            <span>
              9.4 La consegna dei Prodotti si ritiene perfezionata nel momento in cui i Prodotti di cui all'Ordine sono messi a disposizione
              dell'Utente presso l'indirizzo specificato nel modulo d'Ordine.
            </span>
          </Col>
        </Row>
        <Row style={{ marginTop: "1rem" }}>
          <Col className="text-left">
            <span>
              9.5 Nel caso in cui l'Utente non fosse reperibile all'indirizzo e all'orario concordato nel modulo d'Ordine, Delivering contatterà
              tempestivamente l'Utente all'indirizzo email e telefonico indicato nel modulo d'Ordine. Qualora siano decorsi 5 (cinque) minuti
              dall'ultimo tentativo di contatto a mezzo mail e telefono senza riscontro da parte dell'Utente, Delivering si considererà liberata della
              propria obbligazione di consegna dei Prodotti. Si invita pertanto l'Utente a verificare la correttezza dei dati forniti: indirizzo,
              orario e numero di telefono.
            </span>
          </Col>
        </Row>
        <Row style={{ marginTop: "1rem" }}>
          <Col className="text-left">
            <span>
              9.6 Delivering invierà una mail all'Utente per informarlo della mancata consegna. L'Utente assume ogni responsabilità per la consegna o
              per la mancata consegna dei Prodotti secondo le disposizioni che precedono. In caso di mancata consegna per irreperibilità dell'Utente o
              di persona che assuma l'impegno di ritirare i Prodotti per l'Utente, Delivering tratterrà il prezzo pagato dall'Utente a titolo di
              penale.
            </span>
          </Col>
        </Row>
        <Row style={{ marginTop: "1rem" }}>
          <Col className="text-left">
            <span>
              9.7 Spetta all'Utente verificare le condizioni dell'Ordine consegnato. Nel caso in cui la confezione presenti evidenti segni di
              manomissione o alterazione, l'Utente dovrà segnalarlo al rider. In caso di mancata immediata contestazione al personale incaricato di
              Delivering per la consegna, l'Ordine si intende accettato integralmente dall'Utente. L'Utente può comunque inoltrare un reclamo al
              servizio clienti di Delivering qualora riscontrasse difformità, anomalie o danni relativamente alla spedizione o a quanto ordinato entro
              24 ore dalla consegna dei prodotti ordinati e/o spediti, da inviarsi mediante comunicazione scritta all'indirizzo mail
              partner.taporty@gmail.com.
            </span>
          </Col>
        </Row>
        <Row style={{ marginTop: "1rem" }}>
          <Col className="text-left">
            <span style={{ color: "#E22E2E", fontWeight: "bold", fontSize: 20 }}>10. PREZZO, PAGAMENTO E FATTURAZIONE</span>
          </Col>
        </Row>
        <Row style={{ marginTop: "1rem" }}>
          <Col className="text-left">
            <span>
              10.1 Il pagamento dei Prodotti acquistati sulla Piattaforma si effettua mediante Carte di credito dei circuiti VISA, MASTERCARD,
              American Express, i cui pagamenti saranno trattati attraverso un sistema di pagamento online con una connessione server-to-server sicura
              mediante utilizzo del Protocollo SSL (Secure Sockets Layer) con cifratura a 128 bit.
            </span>
          </Col>
        </Row>
        <Row style={{ marginTop: "1rem" }}>
          <Col className="text-left">
            <span>10.2 In caso di mancati pagamenti, Delivering rifiuterà il relativo Ordine di acquisto.</span>
          </Col>
        </Row>
        <Row style={{ marginTop: "1rem" }}>
          <Col className="text-left">
            <span>
              10.3 Delivering s'impegna a far sì che il sistema di pagamento fornito sia sempre disponibile e funzionante, tuttavia non può garantirne
              l'accesso continuo e ininterrotto. Per maggiori informazioni sul sistema di pagamento vi invitiamo a verificare le condizioni della
              piattaforma Axerve di Banca Sella, e della piattaforma di Stripe.
            </span>
          </Col>
        </Row>
        <Row style={{ marginTop: "1rem" }}>
          <Col className="text-left">
            <span>
              10.4 L'Utente riceverà lo scontrino fiscale relativo all'Ordine, emesso dal Partner, direttamente nel contenitore con cui riceverà il
              Prodotto. Tuttavia, qualora l'Utente voglia richiedere la fattura relativa all'Ordine, dovrà specificarlo (a mezzo e-mail) nel momento
              di effettuazione del medesimo. In caso contrario, l'Utente non potrà più richiedere la fattura, avendo già il Partner provveduto
              all'emissione dello scontrino fiscale.
            </span>
          </Col>
        </Row>
        <Row style={{ marginTop: "1rem" }}>
          <Col className="text-left">
            <span>
              10.5 Unicamente se previsto sulla Piattaforma al momento dell'Ordine, l'Utente potrà acquistare i prodotti con le modalità "contanti
              alla consegna": in questo specifica caso, il pagamento del Prodotto da parte dell'Utente, in deroga a quanto specificato nei presenti
              Termini e Condizioni Generali, avverrà – in contanti – direttamente ai "riders" di Delivering al momento della consegna del Prodotto.
              Sono fatte salve tutte le altre condizioni perviste nei presenti Termini e Condizioni Generali, per quanto compatibili.
            </span>
          </Col>
        </Row>
        <Row style={{ marginTop: "1rem" }}>
          <Col className="text-left">
            <span>
              10.6 Qualora l'Utente voglia richiedere l'emissione della fattura relativa alla spedizione, per ragioni contabili, dovrà inviare una
              richiesta a mezzo email al seguente indirizzo partner.taporty@gmail.com prima di procedere con il pagamento dell'Ordine.
            </span>
          </Col>
        </Row>
        <Row style={{ marginTop: "1rem" }}>
          <Col className="text-left">
            <span style={{ color: "#E22E2E", fontWeight: "bold", fontSize: 20 }}>11. GARANZIA E DIFETTI DI CONFORMITÀ</span>
          </Col>
        </Row>
        <Row style={{ marginTop: "1rem" }}>
          <Col className="text-left">
            <span>
              11.1 Alla vendita dei Prodotti ordinati si applicano le garanzie legali previste dagli articoli 129, 130 e 132 del Codice del Consumo.
              L'Utente ha diritto, a sua scelta e a condizione che la tipologia del prodotto lo consenta, al ripristino, senza spese, della conformità
              del prodotto mediante riparazione o sostituzione, oppure ad una adeguata riduzione del prezzo o alla risoluzione del contratto. Il
              Cliente decade da tali diritti se non denuncia a Delivering il difetto di conformità entro il termine di due mesi dalla data in cui ha
              scoperto il difetto, sempre che – come nel caso di specie – non si tratti di Prodotto che per sua natura è deperibile o soggetto a
              scadenza in un termine più breve, nel qual caso il difetto dovrà essere denunciato entro tale termine ridotto, come riportato nel
              precedente art.9.7.
            </span>
          </Col>
        </Row>
        <Row style={{ marginTop: "1rem" }}>
          <Col className="text-left">
            <span style={{ color: "#E22E2E", fontWeight: "bold", fontSize: 20 }}>12. ERRORI E LIMITAZIONI DI RESPONSABILITÀ</span>
          </Col>
        </Row>
        <Row style={{ marginTop: "1rem" }}>
          <Col className="text-left">
            <span>
              12.1 Le informazioni relative ai Prodotti fornite attraverso la Piattaforma sono costantemente aggiornate. Non è tuttavia possibile
              garantire la completa assenza di errori dei quali pertanto Delivering non potrà considerarsi responsabile, salvo in caso di dolo o colpa
              grave.
            </span>
          </Col>
        </Row>
        <Row style={{ marginTop: "1rem" }}>
          <Col className="text-left">
            <span>
              12.2 Delivering si impegna a riportare sul proprio Sito i menù dei ristoranti e le informazioni inerenti il nome del piatto, il prezzo,
              la descrizione, eventuali allergeni e ogni altra informazione rilevante fornita dai propri Partner con riferimento ai menù (Informazioni
              sul Menù).
            </span>
          </Col>
        </Row>
        <Row style={{ marginTop: "1rem" }}>
          <Col className="text-left">
            <span>
              12.3 Resta inteso che Delivering non assume alcuna responsabilità in merito all'accuratezza ed aggiornamento delle Informazioni sul
              Menù.
            </span>
          </Col>
        </Row>
        <Row style={{ marginTop: "1rem" }}>
          <Col className="text-left">
            <span>
              12.4 In caso di dubbio riguardo gli ingredienti che possono cagionare allergie o intolleranze, sul contenuto di un piatto ovvero in
              merito alle Informazioni sul Menù, gli Utenti sono invitati a rivolgersi direttamente al Partner prima di inoltrare l'Ordine al fine di
              ottenere la conferma delle suddette informazioni.
            </span>
          </Col>
        </Row>
        <Row style={{ marginTop: "1rem" }}>
          <Col className="text-left">
            <span>
              12.5 Delivering si riserva il diritto di correggere gli errori, le inesattezze o le omissioni anche dopo che sia stato inviato un
              Ordine, oppure di modificare o aggiornare le informazioni in qualsiasi momento senza preventiva comunicazione, fermi restando i diritti
              dell'Utente così come disciplinati dalle presenti Condizioni Generali e dal Codice del Consumo.
            </span>
          </Col>
        </Row>
        <Row style={{ marginTop: "1rem" }}>
          <Col className="text-left">
            <span>
              12.6 Salvo dolo o colpa grave, viene escluso ogni diritto dell'Utente al risarcimento di danni o al riconoscimento di un indennizzo,
              nonché qualsiasi responsabilità contrattuale o extracontrattuale per danni diretti o indiretti a persone e/o cose, provocati dalla
              mancata accettazione o evasione, anche parziale, di un Ordine.
            </span>
          </Col>
        </Row>
        <Row style={{ marginTop: "1rem" }}>
          <Col className="text-left">
            <span>
              12.7 Le parti non sono responsabili, l'una nei confronti dell'altra, per il ritardo o la mancata esecuzione delle proprie obbligazioni
              qualora tale ritardo o mancata esecuzione derivino da una causa che esula dalle proprie possibilità di controllo, ivi inclusa, a titolo
              esemplificativo e non esaustivo, qualsiasi causa tra le seguenti: catastrofe naturale, provvedimento del governo o della pubblica
              autorità, guerra, inondazione, esplosione o sommossa. Per evitare dubbi, si precisa che le previsioni di cui al presente articolo 12 non
              costituiscono una valida causa di esonero dell'Utente dalle proprie obbligazioni di pagamento in base alle presenti condizioni generali,
              salvo il caso di impossibilità della prestazione derivante da causa a lui non imputabile.
            </span>
          </Col>
        </Row>
        <Row style={{ marginTop: "1rem" }}>
          <Col className="text-left">
            <span style={{ color: "#E22E2E", fontWeight: "bold", fontSize: 20 }}>13. DIRITTI, LEGGE APPLICABILE E FORO COMPETENTE</span>
          </Col>
        </Row>
        <Row style={{ marginTop: "1rem" }}>
          <Col className="text-left">
            <span>
              13.1 I presenti Termini e condizioni di utilizzo del sito sono regolati dal diritto italiano e si interpretano in conformità allo
              stesso. Per ogni eventuale controversia insorgente in connessione con la presente disciplina (ivi incluse controversie non aventi natura
              contrattuale), sarà competente in via esclusiva il Tribunale di Velletri.
            </span>
          </Col>
        </Row>
        <Row style={{ marginTop: "1rem" }}>
          <Col className="text-left">
            <span>
              13.2 Qualora una o più clausole dei presenti Termini e condizioni, o parti di esse, siano o divengano contrarie a norme imperative o di
              ordine pubblico, ovvero vengano dichiarate nulle o inefficaci, esse saranno considerate come non apposte e non incideranno sulla
              validità del contratto, che manterrà efficacia per la restante parte, continuando a regolare ogni rapporto tra Delivering e l'Utente.
            </span>
          </Col>
        </Row>
        <Row style={{ marginTop: "1rem" }}>
          <Col className="text-left">
            <span>
              13.3 L'Utente del sito non può cedere alcun diritto od obbligo previsto dai presenti Termini e condizioni senza il preventivo consenso
              scritto di Delivering.
            </span>
          </Col>
        </Row>
        <Row style={{ marginTop: "1rem" }}>
          <Col className="text-left">
            <span>
              13.5 Delivering potrà cedere qualsiasi diritto od obbligo previsto dai presenti Termini e condizioni a qualsiasi società ad essa
              associata per svolgere l'attività imprenditoriale o che essa acquisisca o a cui essa sia ceduta.
            </span>
          </Col>
        </Row>
        <Row style={{ marginTop: "1rem" }}>
          <Col className="text-left">
            <span style={{ color: "#E22E2E", fontWeight: "bold", fontSize: 20 }}>14. CONTENUTI DELLA PIATTAFORMA E PROPRIETÀ INTELLETTUALE</span>
          </Col>
        </Row>
        <Row style={{ marginTop: "1rem" }}>
          <Col className="text-left">
            <span>
              14.1 I contenuti presenti nel Sito, quali, a titolo esemplificativo, le opere, le immagini, le fotografie, i dialoghi, le musiche, i
              suoni ed i video, i documenti, i disegni, le figure, i loghi ed ogni altro materiale, in qualsiasi formato, compresi i menu, le pagine
              web, la grafica, i colori, gli schemi, gli strumenti, i caratteri ed il design della Piattaforma, i diagrammi, il layouts, i metodi, i
              processi, le funzioni ed il software, sono protetti dal diritto d'autore e da ogni altro diritto di proprietà intellettuale di
              Delivering e degli altri titolari dei diritti. È vietata la riproduzione, la modifica, la duplicazione, la copia, la distribuzione, la
              vendita o comunque lo sfruttamento delle immagini, dei contenuti della Piattaforma se non preventivamente autorizzata per iscritto da
              Delivering. È altresì vietato qualsiasi uso dei contenuti della Piattaforma per fini commerciali e/o pubblicitari.
            </span>
          </Col>
        </Row>
        <Row style={{ marginTop: "1rem" }}>
          <Col className="text-left">
            <span>
              4.2 Tutti gli altri segni distintivi che contraddistinguono i Prodotti venduti nella Piattaforma sono marchi registrati dei rispettivi
              titolari e sono utilizzati da Delivering in virtù di separati accordo di licenza, allo solo scopo di contraddistinguere, descrivere e
              pubblicizzare i Prodotti in vendita sulla Piattaforma.
            </span>
          </Col>
        </Row>
        <Row style={{ marginTop: "1rem" }}>
          <Col className="text-left">
            <span>
              14.3 Qualsiasi uso dei segni distintivi sopra menzionati non conforme alla legge, se non espressamente autorizzato, è vietato. Non è in
              alcun modo consentito usare alcun segno distintivo presente nella Piattaforma per trarre indebitamente vantaggio dal carattere
              distintivo o dalla rinomanza di questi o in modo da recare pregiudizio agli stessi ed ai loro titolari.
            </span>
          </Col>
        </Row>
        <Row style={{ marginTop: "1rem" }}>
          <Col className="text-left">
            <span>
              14.4 In nessun caso l'Utente può alterare, cambiare, modificare o adattare la Piattaforma, né il materiale messo a disposizione da
              Delivering.
            </span>
          </Col>
        </Row>
        <Row style={{ marginTop: "1rem" }}>
          <Col className="text-left">
            <span style={{ color: "#E22E2E", fontWeight: "bold", fontSize: 20 }}>15. PRIVACY</span>
          </Col>
        </Row>
        <Row style={{ marginTop: "1rem" }}>
          <Col className="text-left">
            <span>
              15.1 Tutte le informazioni relative al trattamento dei dati personali dell'Utente possono essere ottenute consultando l'Informativa sul
              trattamento dei dati personali presente sul Sito.
            </span>
          </Col>
        </Row>
        <Row style={{ marginTop: "1rem" }}>
          <Col className="text-left">
            <span style={{ color: "#E22E2E", fontWeight: "bold", fontSize: 20 }}>16. COUPON SCONTO</span>
          </Col>
        </Row>
        <Row style={{ marginTop: "1rem" }}>
          <Col className="text-left">
            <span>
              16.1 Delivering offre la possibilità agli Utenti, a propria esclusiva discrezione, di ricevere coupon sconto. I coupon possono essere
              concessi, a titolo esemplificativo e non esaustivo, al momento dell'iscrizione, in seguito alla registrazione di un amico invitato
              dall'Utente, etc.
            </span>
          </Col>
        </Row>
        <Row style={{ marginTop: "1rem" }}>
          <Col className="text-left">
            <span>
              16.2 La validità e il valore del coupon sono specificati al momento dell'emissione dello stesso. In caso di coupon sconto offerti da
              Delivering in corrispondenza di un'offerta specifica, tali buoni non potranno essere utilizzati per altre offerte.
            </span>
          </Col>
        </Row>
        <Row style={{ marginTop: "1rem" }}>
          <Col className="text-left">
            <span>16.3 I coupon non sono trasferibili né cedibili; e non sono convertibili in contanti né fanno maturare interessi.</span>
          </Col>
        </Row>
        <Row style={{ marginTop: "1rem" }}>
          <Col className="text-left">
            <span>
              16.4 Delivering si riserva la facoltà di non accettare coupon per Ordini di importo inferiore ad una determinata soglia. Delivering si
              impegna a rendere nota tale circostanza al momento dell'Ordine effettuato dall'Utente.
            </span>
          </Col>
        </Row>
        <Row style={{ marginTop: "1rem" }}>
          <Col className="text-left">
            <span>
              16.6 Nel caso in cui l'importo del coupon superasse l'importo dell'acquisto, Delivering non rimborserà, né riaccrediterà l'importo
              residuo all'Utente.
            </span>
          </Col>
        </Row>
        <Row style={{ marginTop: "1rem" }}>
          <Col className="text-left">
            <span>
              16.7 Nel caso in cui l'Ordine dell'Utente superi il valore dello sconto, la differenza di importo potrà essere saldata dall'Utente
              utilizzando i normali mezzi di pagamento.
            </span>
          </Col>
        </Row>
        <Row style={{ marginTop: "1rem" }}>
          <Col className="text-left">
            <span>16.8 Delivering si riserva la facoltà di accettare un solo coupon per Ordine.</span>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Terms;
