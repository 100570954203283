import React from "react";
//------------- Bootstrap------------------
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import Container from "react-bootstrap/Container";
//------------- Other import -----------
import Logo from "../../../Assets/Images/logo.png";
import Apple from "../../../Assets/Images/apple_store.png";
import Google from "../../../Assets/Images/google_play.png";
import classes from "./Footer.module.css";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <>
      <Container fluid>
        <Row className={classes.rowBackgroundColor}>
          <Col style={{ padding: 30 }}>
            <Row>
              <Col xs={12} md={3}>
                <Image className={classes.imageStyle} src={Logo} alt="Taporty" />
              </Col>
              <Col xs={12} md={9}>
                {/* 
                            <Row>
                                <Col><span className={classes.footerTextHeader}>Taporty</span></Col>
                                <Col><span className={classes.footerTextHeader}>Note legali</span></Col>
                                <Col><span className={classes.footerTextHeader}>Dove siamo</span></Col>
                                <Col><span className={classes.footerTextHeader}>Scarica app</span></Col>
                            </Row> */}
                <Row style={{ marginTop: 10 }}>
                  <Col xs={6} lg={3}>
                    <Row>
                      <Col>
                        <span className={classes.footerTextHeader}>Taporty</span>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Link to="/" target="_blank">
                          <p className={classes.footerTextItem}>Home</p>
                        </Link>
                        <Link to="/chi-siamo" target="_blank">
                          <p className={classes.footerTextItem}>Chi siamo</p>
                        </Link>
                        <Link to="/come-funziona" target="_blank">
                          <p className={classes.footerTextItem}>Come funziona</p>
                        </Link>
                        <Link to="/lavora-con-noi" target="_blank">
                          <p className={classes.footerTextItem}>Lavora con noi</p>
                        </Link>
                        {
                          //<a href='https://joinus.taporty.it/' target='_blank' rel="noreferrer"><p className={classes.footerTextItem}>Lavora con noi</p></a>
                        }
                      </Col>
                    </Row>
                  </Col>
                  <Col>
                    <Row>
                      <Col>
                        <span className={classes.footerTextHeader}>Note legali</span>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Link to="/termini-e-condizioni" target="_blank">
                          <p className={classes.footerTextItem}>Termini e condizioni</p>
                        </Link>
                        <a href="https://www.iubenda.com/privacy-policy/60234852" target="_blank" rel="noreferrer">
                          <p className={classes.footerTextItem}>Privacy policy</p>
                        </a>
                      </Col>
                    </Row>
                  </Col>
                  <Col>
                    <Row>
                      <Col>
                        <span className={classes.footerTextHeader}>Dove siamo</span>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <p className={classes.footerTextItem}>Latina</p>
                        <p className={classes.footerTextItem}>Latina Scalo</p>
                        <p className={classes.footerTextItem}>Priverno</p>
                        <p className={classes.footerTextItem}>Sant'Egidio alla vibrata</p>
                        <p className={classes.footerTextItem}>Ceccano</p>
                      </Col>
                    </Row>
                  </Col>
                  <Col>
                    <Row>
                      <Col>
                        <span className={classes.footerTextHeader}>Scarica app</span>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Row>
                          <Col>
                            <a href="https://apps.apple.com/it/app/taporty/id1510551452" target="_blank">
                              <Image src={Apple} className={classes.storeLogo} />
                            </a>
                          </Col>
                        </Row>
                        <Row style={{ marginTop: 10 }}>
                          <Col>
                            <a href="https://play.google.com/store/apps/details?id=it.taporty.customer&hl=en&gl=US" target="_blank">
                              <Image src={Google} className={classes.storeLogo} />
                            </a>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
            <div className={classes.separatorDiv} />
            <Row style={{ marginTop: 20 }}>
              <Col>
                <p className={classes.contactTextElement}>E-mail: infotaporty@gmail.com</p>
                <p className={classes.contactTextElement}>Telefono: +39 327 7617824</p>
                <p className={classes.contactTextElement}>Facebook: Taporty</p>
                <p className={classes.contactTextElement}>Instagram: taporty_delivery_official</p>
                <p className={classes.contactTextElement}>Copyright © Taporty.it 2021</p>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Footer;
